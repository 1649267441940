import React, { useRef } from "react";
import { IGenericObject } from "../../global";
import Button from "../Button";
import { EnumButtonVariants } from "../Button/Button";

import "./InputFile.scss";

interface IInputFileProps extends IGenericObject {
  label: string;
  fileName: string | undefined;
  onFileSelect(file: File | null): any;
}

const InputFile: React.FC<IInputFileProps> = ({ label, fileName, onFileSelect }) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      onFileSelect(e.currentTarget.files[0]);
    }
  };

  return (
    <div className="file-uploader">
      <input
        type="file"
        accept="image/*,application/pdf"
        ref={fileInput}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileSelect(e)}
      />
      <Button variant={EnumButtonVariants.Primary} onClick={() => fileInput.current?.click()}>
        {label}
      </Button>
      <p>{fileName}</p>
    </div>
  );
};

export default InputFile;

import { IGenericObject } from "../../../global";

export const LIST_LOAD = "LIST_LOAD";
export const LIST_LOAD_ERROR = "LIST_LOAD_ERROR";
export const LIST_CHANGE_PAGE = "LIST_CHANGE_PAGE";
export const LIST_SEARCH = "LIST_SEARCH";
export const LIST_FULFILLED = "LIST_FULFILLED";

export interface IListSpecificFields {
  image_url?: string;
  name?: string;
  type?: string;
}

export interface IList extends IListSpecificFields {
  id: number;
  active: boolean;
  status: string
  first_deposit: boolean
}

export interface IListState {
  loading: boolean;
  loaded: boolean;
  list: IList[];
  controller: string;
  lastLoaded: string;
  page: number;
  pages: number;
  search: string;
  filter: IGenericObject;
  limit: number;
}

export interface IListLoadPayload {
  controller: string;
  page?: number;
}

export interface IListLoad {
  type: string;
  payload: IListLoadPayload;
}

export interface IListLoadApi {
  controller: string;
  page: number;
  search: string;
  limit: number;
  filter?: {};
  params?: {};
}

export interface IListResult {
  meta: {
    total: number;
    per_page: number;
    last_page: number;
  };
  data: IList[];
}

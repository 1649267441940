import { useDispatch } from "react-redux";
import { APP_NAME } from "../../config";
import { doLogout } from "../../state/ducks/login/actions";
import Button from "../Button";
import Menu from "../Menu";

const Header = () => {
  const dispatch = useDispatch();

  return (
    <div className="header">
      <img src="/logo-bingotine.png" alt={`${APP_NAME} - Admin`} className="logo" />
      <Menu />
      <Button classes="exit" onClick={() => dispatch(doLogout())}>
        <span>SAIR</span>
        <span className="material-icons icon">close</span>
      </Button>
    </div>
  );
};

export default Header;

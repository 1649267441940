import { takeLatest, all, call, put } from "redux-saga/effects";
import { rejectApi } from "../../../utils/Functions";
import {  toastShow } from "../global/actions";
import { listLoad } from "../list/actions";
import { withdrawError, withdrawSuccess } from "./actions";
import { withdrawCancelApi, withdrawConcludeApi, withdrawRefundApi } from "./api";
import { WITHDRAW_CANCEL, WITHDRAW_CONCLUDE, WITHDRAW_REFUND } from "./types";

export function* withdrawSaga(): any {
  yield all([yield takeLatest(WITHDRAW_CONCLUDE, withdrawConcludeWorker)]);
  yield all([yield takeLatest(WITHDRAW_REFUND, withdrawRefundWorker)]);
  yield all([yield takeLatest(WITHDRAW_CANCEL, withdrawCancelWorker)]);
}


function* withdrawConcludeWorker({ payload: { id } }: any) {
  try {
    yield call(withdrawConcludeApi, id);
    yield put(toastShow("success", 'Saque concluído com sucesso!'));
    yield put(withdrawSuccess());
    yield put(listLoad({ controller: 'withdraws' }));
  } catch (err) {
    const error = rejectApi(err);
    yield put(toastShow("error", error));
    yield put(withdrawError());
  }
}

function* withdrawRefundWorker({ payload: { id } }: any) {
  try {
    yield call(withdrawRefundApi, id);
    yield put(toastShow("success", 'Saque reembolsado com sucesso!'));
    yield put(withdrawSuccess());
    yield put(listLoad({ controller: 'withdraws' }));
  } catch (err) {
    const error = rejectApi(err);
    yield put(toastShow("error", error));
    yield put(withdrawError());
  }
}

function* withdrawCancelWorker({ payload: { id } }: any) {
  try {
    yield call(withdrawCancelApi, id);
    yield put(toastShow("success", 'Saque cancelado com sucesso!'));
    yield put(withdrawSuccess());
    yield put(listLoad({ controller: 'withdraws' }));
  } catch (err) {
    const error = rejectApi(err);
    yield put(toastShow("error", error));
    yield put(withdrawError());
  }
}
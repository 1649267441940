export const WITHDRAW_CONCLUDE = "WITHDRAW_CONCLUDE";
export const WITHDRAW_REFUND = "WITHDRAW_REFUND";
export const WITHDRAW_CANCEL = "WITHDRAW_CANCEL";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_ERROR = "WITHDRAW_ERROR";

export interface IWithdrawState {
  loading: boolean
  error: {
    code: number;
    message: string;
  };
}

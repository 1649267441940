import { IReduxAction } from "../../../global";
import {
  ICrudState,
  CRUD_INSERT,
  CRUD_LOAD_FULFILLED,
  CRUD_SUCCESS,
  CRUD_UPDATE,
  CRUD_LOAD,
  CRUD_ERROR,
  CRUD_OPTIONS_LOAD_FULFILLED,
} from "./types";

export const initialState: ICrudState = {
  loading: false,
  sending: false,
  success: false,
  data: {},
  options: {}
};

export default function crudReducer(
  state = initialState,
  { type, payload }: IReduxAction
): ICrudState {
  switch (type) {
    case CRUD_LOAD:
      return {
        ...state,
        loading: true,
      };
    case CRUD_SUCCESS:
    case CRUD_LOAD_FULFILLED:
      return {
        ...state,
        ...payload,
        loading: false,
        sending: false,
      };
    case CRUD_INSERT:
    case CRUD_UPDATE:
      return {
        ...state,
        sending: true,
      };
    case CRUD_ERROR:
      return {
        ...state,
        sending: false,
        success: false,
      };

    case CRUD_OPTIONS_LOAD_FULFILLED:
      return {
        ...state,
        options: payload?.data
      };

    default:
      return state;
  }
}

import { FC } from "react";

import "./Welcome.scss";

const Welcome: FC = () => (
  <div className="welcome">
    <p className="title">Bem vindo!</p>
    <div className="content">
    </div>
  </div>
);

export default Welcome;

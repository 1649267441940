import React, { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { controllerToRoute } from "../../config";
import { listLoad, listSearch } from "../../state/ducks/list/actions";
import { IRootState } from "../../state/ducks/types";

interface ISearch {
  controller: string;
}

const Search: FC<ISearch> = ({ controller }) => {
  const dispatch = useDispatch();

  const { search } = useSelector((state: IRootState) => state.list);
  const [timeoutClear, setTimeoutClear] = useState<NodeJS.Timeout>();

  const routeToLoad = controllerToRoute(controller);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (timeoutClear) {
          clearTimeout(timeoutClear);
          setTimeoutClear(undefined);
        }
        dispatch(
          listLoad({
            controller: routeToLoad,
          })
        );
      }
    },
    [timeoutClear, routeToLoad]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.currentTarget.value === "") {
        setTimeoutClear(
          setTimeout(() => {
            dispatch(
              listLoad({
                controller: routeToLoad,
              })
            );
          }, 500)
        );
      }
    },
    [routeToLoad]
  );

  const handleChangeInput = (value: string) => {
    dispatch(
      listSearch({
        search: value,
      })
    );
  };

  return (
    <div className="filter">
      <label htmlFor="search">Buscar:</label>
      <input
        type="text"
        name="search"
        id="search"
        onChange={(e) => handleChangeInput(e.currentTarget.value)}
        onBlur={(e) => handleOnBlur(e)}
        onKeyDown={handleKeyDown}
        value={search}
      />
    </div>
  );
};

export default Search;

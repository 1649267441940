import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { controllerToRoute } from "../../config";
import { listLoad, listSearch } from "../../state/ducks/list/actions";
import { IRootState } from "../../state/ducks/types";

interface IFilter {
  controller: string;
}

const Filter: FC<IFilter> = ({ controller }) => {
  const dispatch = useDispatch();

  const {
    filter: { type },
  } = useSelector((state: IRootState) => state.list);

  const routeToLoad = controllerToRoute(controller);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    dispatch(
      listSearch({
        filter: {
          type: value,
        },
      })
    );
    dispatch(
      listLoad({
        controller: routeToLoad,
      })
    );
  };

  return (
    <div className="filter">
      <label htmlFor="Filter">Filtro:</label>
      <select name="type" id="type" onChange={handleChange} value={type}>
        <option value="">TODOS</option>
        <option value="IMAGEM">IMAGEM</option>
        <option value="FILTRO">FILTRO</option>
      </select>
    </div>
  );
};

export default Filter;

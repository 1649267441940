import { IReduxAction } from "../../../global";
import {
  DEPOSIT_APPROVE, DEPOSIT_ERROR, DEPOSIT_FIRST, DEPOSIT_SUCCESS,
} from "./types";

export const depositApprove = (id: number): IReduxAction => ({
  type: DEPOSIT_APPROVE,
  payload: {
    id,
  },
});

export const depositFirst = (id: number): IReduxAction => ({
  type: DEPOSIT_FIRST,
  payload: {
    id,
  },
});

export const depositSuccess = (): IReduxAction => ({
  type: DEPOSIT_SUCCESS,
});

export const depositError = (): IReduxAction => ({
  type: DEPOSIT_ERROR,
});
import { AppearanceTypes } from "react-toast-notifications";
import { IGenericObject } from "../../../global";

export const TOAST_SHOW = "TOAST_SHOW";
export const TOAST_CLEAR = "TOAST_CLEAR";

export const REDIRECT = "REDIRECT";
export const SET_ROUTE = "SET_ROUTE";

export const GET_FIELDS = "GET_FIELDS";
export const SET_FIELDS = "SET_FIELDS";

export const SET_ERROR = "SET_ERROR";

export interface IGlobalState {
  initialized: boolean;
  appearance: AppearanceTypes;
  message: string;
  redirectRoute: string;
  route: string;
  fields: IGenericObject;
  error: {
    code: number;
    message: string;
  };
}

import { IGenericObject } from "../global";
import { depositApprove, depositFirst } from "../state/ducks/deposit/actions";
import { IList } from "../state/ducks/list/types";
import { withdrawApprove, withdrawCancel, withdrawRefund } from "../state/ducks/withdraw/actions";
import { capitalizeFirstLetter } from "../utils/Functions";

interface ICustomAction {
  icon: string;
  condition?: (row: IList | IGenericObject) => boolean;
  action: Function;
  buttonTitle?: string;
  title?: string;
  hideList?: boolean;
}

interface ICustomActions {
  [key: string]: ICustomAction[];
}

export interface ISpecialField {
  type: string;
  options: any[];
  mask: string;
  allowDecimals: boolean;
  otherAttributes: IGenericObject;
  table: string
}

export const dateCols = ["data", "published_from", "published_to", "created_at", "updated_at", 'send_date'];

export const imageCols = ["image_url"];

export const phoneCols = ["phone"];

export const decimalCols = ["amount", "value"];

export const colDBHumanized = (col: string, fields?: IGenericObject) =>
  (fields && fields[col]) || col;

export const omitColumns: IGenericObject = {
  all: ["ip"],
  banners: ["link", "image"],
  players: ["accept", "accept_ip", "first_deposit", "birth_date", "gender"],
  "deposits-first": ["first_deposit"],
  games: ["description", "game_provider_id", "game_category_id", "external_url"],
  withdraws: ["pix_key", "pix_key_type", "player_bets_total", "player_drawable_balance", "player_comission_balance"],
  settings: ["value", "type"]
};

export const crudEmptyFields: IGenericObject = {
  banners: {
    id: "",
    name: "",
    image: "",
    link: "",
    order: 0,
    published_from: null,
    published_to: null,
    active: false,
  },
  players: {
    full_name: "",
    nickname: "",
    email: "",
    phone: "",
    status: "INATIVO",
    accept: false,
    accept_ip: "",
    password: "",
    password_confirmation: "",
    deposits_total: "", 
    withdraws_total: "", 
    bonus_total: "", 
    bets_total: "", 
    drawable_balance: "",
    comission_balance: "",
    total_active_indications: "",
    accounts: ""
  },
  bots: {
    full_name: "",
    nickname: "",
    gender: "",
  },
  games: {
    name: "",
    description: "",
    code: "",
    active: false,
    image: "",
    image_url: "",
    external_url: "",
    game_provider_code: "",
    game_category_id: "",
    count_bets_total: 0,
    sum_bets_total: 0,
    count_prizes_total: 0,
    sum_prizes_total: 0,
  },
  users: {
    full_name: "",
    status: "ATIVO",
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
  },
  deposits: {
    status: "",
    payment_type: "",
    amount: "",
    created_at: "",
    player_nickname: "",
    player_email: "",
    ip: "",
  },
  'chat-phrases': {
    message: "",
    gender: "",
    active: true,
    auto: true,
  },
  credits: {
    type: "EMAIL",
    account_type_code: "",
    email: "",
    amount: 0,
  },
  withdraws: {
    status: "",
    amount: "",
    pix_key: "",
    pix_key_type: "",
    type: "",
    player_id: "",
    player_nickname: "",
    player_email: "",
    player_bets_total: "",
    player_drawable_balance: "",
    player_comission_balance: "",
    ip: ""
  },
  settings: {
    type: "",
    description: "",
    value: "",
  },
  "notification-messages": {
    id: "",
    message: "",
    //send_date: null,
  },
};

export const crudNoRequiredFields: IGenericObject = {
  banners: {
    image: ["edit"],
    link: ["insert", "edit"],
  },
  games: {
    description: ["insert", "edit"],
    image_url: ["insert", "edit"],
    image: ["insert", "edit"],
    external_url: ["insert", "edit"],
  },
  fonts: {
    file: ["edit"],
  },
  images: {
    image: ["edit"],
  },
  "notification-messages": {
    send_date: ["insert"],
  },
};

export const crudNoEditFields: IGenericObject = {
  clients: ["accept"],
};

export const crudNoInsertFields: IGenericObject = {
  games: ["count_bets_total", "sum_bets_total", "count_prizes_total", "sum_prizes_total", "image_url"],
};

export const crudDisabledEditFields: IGenericObject = {
  games: ["sum_prizes_total", "count_prizes_total", "sum_bets_total", "count_bets_total"],
  withdraws: ["pix_key", "pix_key_type", "type", "status", "player_nickname", "player_email", "player_bets_total", "player_drawable_balance", "player_comission_balance", "player_id"],
  players: ["accept_ip", "accept", "total_active_indications", "comission_balance", "drawable_balance", "bets_total", "deposits_total", "withdraws_total", "bonus_total"],
  settings: ["type"]
};

export const crudSpecialFields: IGenericObject = {
  banners: {
    image: {
      type: "uploader",
    },
    published_from: {
      type: "datetime",
    },
    published_to: {
      type: "datetime",
    },
    active: {
      type: "checkbox",
    },
    order: {
      type: "integer",
    },
  },
  players: {
    password: {
      type: "password",
    },
    password_confirmation: {
      type: "password",
    },
    phone: {
      type: "mask",
      mask: "(99)99999-9999",
    },
    status: {
      type: "select",
      options: ["INATIVO", "ATIVO", "BLOQUEADO"],
    },
    accept: {
      type: "checkbox",
    },
    comission_balance: {
      type: "decimal"
    },
    drawable_balance: {
      type: "decimal"
    },
    accounts: {
      type: "accounts",
      options: ["BNS", "DPS", "PRM", "PRC", "IND"],
    }
  },
  bots: {
    gender: {
      type: "select",
      options: ["M", "F"],
    },
  },
  games: {
    active: {
      type: "checkbox",
    },
    image: {
      type: "uploader",
    },
    image_url: {
      type: "image",
    },
    sum_prizes_total: { 
      type: "decimal" 
    },
    sum_bets_total: { 
      type: "decimal" 
    },
    game_provider_code: {
      type: "dynamic_select",
      table: "game_providers"
    },
    game_category_id: {
      type: "dynamic_select",
      table: "game_categories"
    },
  },
  deposits: {
    created_at: {
      type: "datetime",
    },
    amount: { 
      type: "decimal" 
    }
  },
  users: {
    password: {
      type: "password",
    },
    password_confirmation: {
      type: "password",
    },
    status: {
      type: "select",
      options: ["INATIVO", "ATIVO"],
    },
  },
  credits: {
    type: {
      type: "select",
      options: ["EMAIL"],
    },
    account_type_code: {
      type: "select",
      options: ["BNS", "PRM", "PRC", "DPS", "IND"],
    },
    amount: { 
      type: "decimal" 
    }
  },
  'chat-phrases': {
    gender: {
      type: "select",
      options: ["MASCULINO", "FEMININO", "NEUTRO"],
    },
    active: {
      type: "checkbox",
    },
    auto: {
      type: "checkbox",
    },
  },
  withdraws: {
    amount: {
      type: "decimal",
    },
    player_drawable_balance: {
      type: "decimal",
    },
    player_comission_balance: {
      type: "decimal",
    },
  },
  settings: {
    value: {
      type: "setting",
    },
  },
  'notification-messages': {
    send_date: {
      type: "datetime",
    },
    message: {
      type: "textarea",
    },
  }
};

export const crudCustomData: IGenericObject = {};

export const crudCanEdit: IGenericObject = {
  deposits: false,
};

export const ignoreFields = ["updated_at"];

export const disabledFields = ["id", "ip"];

export const noInsertActions: IGenericObject = ["deposits", "transactions", "withdraws", "players", "exchanges", "bots", "settings", "deposits-first"];

export const noEditActions: IGenericObject = ["transactions", "deposits", "credits", "exchanges", "notification-messages", "deposits-first"];

export const noViewActions: IGenericObject = ["credits", "transactions", "exchanges", "notification-messages", "deposits-first"];

export const noDeleteActions: IGenericObject = ["games", "withdraws", "deposits", "credits", "transactions", "exchanges", "bots", "settings", "deposits-first"];

export const customActions: ICustomActions = {
  deposits: [
    {
      icon: "check",
      condition: (row) => row.status !== "APROVADO",
      action: (dispatch: any, { id }: IList) => {
        if (confirm("Deseja aprovar este depósito?")) {
          dispatch(depositApprove(id));
        }
      },
      title: "Aprovar depósito",
    },
  ],
  'deposits-first': [
    {
      icon: "clear",
      condition: (row) => row.first_deposit,
      action: (dispatch: any, { id }: IList) => {
        if (confirm("Remover jogador da lista?")) {
          dispatch(depositFirst(id));
        }
      },
      title: "Remover jogador da lista",
    },
  ],
  withdraws: [
    {
      icon: "check",
      condition: (row) => row.status === "PENDENTE",
      action: (dispatch: any, { id }: IList) => {
        if (confirm("Deseja concluir este saque?")) {
          dispatch(withdrawApprove(id));
        }
      },
      title: "Concluir saque",
    },
    {
      icon: "clear",
      condition: (row) => row.status === "PENDENTE",
      action: (dispatch: any, { id }: IList) => {
        if (confirm("Deseja cancelar este saque?")) {
          dispatch(withdrawCancel(id));
        }
      },
      title: "Cancelar saque",
    },
    {
      icon: "keyboard_return",
      condition: (row) => row.status === "CONCLUÍDO",
      action: (dispatch: any, { id }: IList) => {
        if (confirm("Deseja reembolsar este saque?")) {
          dispatch(withdrawRefund(id));
        }
      },
      title: "Reembolsar saque",
    },
  ]
};

export const customColData: IGenericObject = {};

export const controllerToRoute = (route: string) => {
  switch (route) {
    default:
      return route;
  }
};

export const actionHumanize: IGenericObject = {
  edit: "Alterar",
  insert: "Novo",
};

export const controllerHumanize = (controller: string): string => {
  switch (controller) {
    case "users":
      return "Usuários";
    case "exchanges":
      return "Trocas";
    case "players":
      return "Jogadores";
    case "withdraws":
      return "Saques";
    case "transactions":
      return "Transações";
    case "games":
      return "Jogos";
    case "credits":
      return "Créditos";
    case "chat-phrases":
      return "Mensagens Chat";
    case "deposits":
      return "Depósitos";
    case "deposits-first":
      return "Primeiro Depósito";
    case "bots":
      return "Bots";
    case "settings":
      return "Configurações";
    case "notification-messages":
      return "Notificações";
    default:
      return capitalizeFirstLetter(controller);
  }
};

export const humanizeErrorMessage = (errMessage: string): string => {
  switch (errMessage) {
    default:
      return errMessage;
  }
};

import { FC } from "react";
import { useSelector } from "react-redux";

import { IRootState } from "../../state/ducks/types";
import { IList } from "../../state/ducks/list/types";

import "./List.scss";
import { renderTh } from "../../utils/Functions";

interface ITableHead {
  controller: string;
  list: IList[];
}

const TableHead: FC<ITableHead> = ({ controller, list }) => {
  const { fields } = useSelector((state: IRootState) => state.global);

  return (
    <tr className="thead">
      {Object.entries(list[0]).map((value, kth) => renderTh(controller, value, kth, fields))}
      <th></th>
    </tr>
  );
};

export default TableHead;

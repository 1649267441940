import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Button from "../../components/Button";
import { EnumButtonVariants } from "../../components/Button/Button";
import { IS_DEV, ROUTE_HOME } from "../../config";
import { doLogin } from "../../state/ducks/login/actions";
import { IRootState } from "../../state/ducks/types";

import "./Login.scss";

const Login: FC = () => {
  const dispatch = useDispatch();

  const [login, setLogin] = useState<string>(IS_DEV ? "inversa" : "");
  const [password, setPassword] = useState<string>(IS_DEV ? "123456" : "");

  const { sending, loggedIn } = useSelector((state: IRootState) => state.login);

  if (loggedIn) {
    return (
      <Redirect
        to={{
          pathname: ROUTE_HOME,
          state: { from: "/login" },
        }}
      />
    );
  }

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(doLogin(login, password));
  };

  return (
    <div className="login">
      <img src="/logo-bingotine.png" className="logo" />
      <span className="title">Insira seu login e senha para acessar</span>
      <form onSubmit={submitHandler}>
        <input
          type="login"
          name="login"
          value={login}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)}
          placeholder="Login"
        />
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          placeholder="Senha"
        />
        <div className="buttons">
          <Button
            text={sending ? "ENVIANDO..." : "ENTRAR"}
            variant={EnumButtonVariants.Primary}
            disabled={sending}
          />
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { FC, ReactNode } from "react";
import Button from "../../components/Button";
import { listChangePage } from "../../state/ducks/list/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

interface IPagination {
  controller: string;
  page: number;
  pages: number;
}

const Pagination: FC<IPagination> = ({ controller, page, pages }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pagination: ReactNode[] = [];

  const changePage = (p: number) => {
    history.push(`/${controller}/${p}`);
    dispatch(listChangePage(p));
  };

  const getButton = (pc: number) =>
    pagination.push(<Button key={pc.toString()} onClick={() => changePage(pc)} text={pc} />);

  if (pages === 0) return null;

  //keep last 5 buttons in the end
  if (page - 4 > 0 && page >= pages) getButton(page - 4);
  if (page - 3 > 0 && page + 1 >= pages) getButton(page - 3);
  if (page - 2 > 0) getButton(page - 2);
  if (page - 1 > 0) getButton(page - 1);

  pagination.push(<Button key={page} disabled text={page} />);

  if (page + 1 <= pages) getButton(page + 1);
  if (page + 2 <= pages) getButton(page + 2);
  if (page + 3 <= pages && page < 3) getButton(page + 3);
  if (page + 4 <= pages && page < 2) getButton(page + 4);

  return (
    <div className="pagination">
      {/*first, back page*/}
      <Button
        classes={"material-icons"}
        disabled={page <= 3}
        onClick={() => changePage(1)}
        text={"arrow_back_iosarrow_back_ios"}
      />
      <Button
        classes={"material-icons"}
        disabled={page === 1}
        onClick={() => changePage(page - 1)}
        text={"arrow_back_ios"}
      />

      {pagination}

      {/*next, last page*/}
      <Button
        classes={"material-icons"}
        disabled={page >= pages}
        onClick={() => changePage(page + 1)}
        text={"arrow_forward_ios"}
      />
      <Button
        classes={"material-icons"}
        disabled={page + 2 >= pages}
        onClick={() => changePage(pages)}
        text={"arrow_forward_iosarrow_forward_ios"}
      />
    </div>
  );
};

export default Pagination;

import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import classNames from "classnames";

import { listChangePage, listLoad, listSearch } from "../../state/ducks/list/actions";
import { IRootState } from "../../state/ducks/types";

import "./List.scss";
import { IRoute } from "../../global";
import { controllerToRoute, noInsertActions } from "../../config";

import Head from "../../components/Head";
import Search from "./Search";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import Pagination from "./Pagination";
import ActionButtons from "./ActionButtons";
import Filter from "./Filter";

export enum EListMode {
  List = "list",
  Gallery = "gallery",
}

interface IList {
  mode?: EListMode;
}

const List: FC<IList> = ({ mode = EListMode.List }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const params: IRoute = useParams();
  const { controller } = params;
  const pageUri = parseInt(params?.page || "1");
  const routeToLoad = controllerToRoute(controller);

  const {
    list,
    loaded,
    loading,
    lastLoaded,
    page: pageState,
    pages,
  } = useSelector((state: IRootState) => state.list);

  const page = pageUri || pageState;

  const hasInsertAction = !noInsertActions.includes(controller);

  const listReady = loaded && list.length > 0;

  const handleClickEdit = (id: string, e?: React.MouseEvent) => {
    e?.stopPropagation();
    history.push(`/${controller}/edit/${id}`);
  };

  useEffect(() => {
    if (((list.length === 0 && !loaded) || routeToLoad !== lastLoaded) && !loading) {
      dispatch(listSearch({}));
      dispatch(
        listLoad({
          controller: routeToLoad,
          page,
        })
      );
    }
  }, [dispatch, controller, lastLoaded, list, loading, page]);

  useEffect(() => {
    if (pageUri !== pageState && !loading && loaded) {
      dispatch(listChangePage(pageUri));
    }
  }, [pageUri, pageState, loading, loaded]);

  return (
    <div className="list">
      <Head hasInsertAction={hasInsertAction} controller={controller}>
        {mode === EListMode.Gallery && <Filter controller={controller} />}
      </Head>
      {
        <>
          {mode === EListMode.List && <Search controller={controller} />}
          {loading ? (
            <p>Carregando...</p>
          ) : listReady ? (
            mode === EListMode.List ? (
              <div className="table">
                <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <TableHead controller={controller} list={list} />
                    <TableRow
                      controller={controller}
                      list={list}
                      handleClickEdit={handleClickEdit}
                    />
                  </tbody>
                </table>
                <Pagination controller={controller} page={page} pages={pages} />
              </div>
            ) : (
              <div className="gallery">
                {list.map((row) => (
                  <div
                    key={`im_${row.id}`}
                    className="image"
                    onClick={() => handleClickEdit(row.id.toString())}
                  >
                    <img src={row.image_url} />
                    <div className="info">
                      <span className="name">
                        <span>
                          <b>Nome</b>: {row.name}
                        </span>
                        <span className={classNames("status", { active: row.active })}>
                          <b>Ativo</b>:
                          <span className="icon material-icons">
                            {row.active ? "done" : "close"}
                          </span>
                        </span>
                      </span>
                      <span>
                        <b>Tipo</b>: {row.type}
                      </span>
                    </div>
                    <ActionButtons
                      controller={controller}
                      row={row}
                      handleClickEdit={handleClickEdit}
                    />
                  </div>
                ))}
              </div>
            )
          ) : (
            <p>Nenhum registro encontrado</p>
          )}
        </>
      }
    </div>
  );
};

export default List;

export const DEPOSIT_APPROVE = "DEPOSIT_APPROVE";
export const DEPOSIT_FIRST = "DEPOSIT_FIRST";
export const DEPOSIT_SUCCESS = "DEPOSIT_SUCCESS";
export const DEPOSIT_ERROR = "DEPOSIT_ERROR";

export interface IDepositState {
  loading: boolean
  error: {
    code: number;
    message: string;
  };
}

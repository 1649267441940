import { REHYDRATE } from "redux-persist";
import { REDUX_KEY } from "../../../config";
import { IReduxAction } from "../../../global";
import { ILoginState, LOGGED_IN, LOGIN, LOGIN_REJECT, LOGOUT } from "./types";

export const initialState: ILoginState = {
  initialized: process.env.REACT_APP_ENABLE_PERSIST !== "true",
  sending: false,
  loggedIn: false,
  token: "",
  refreshToken: "",
  user: {
    id: 0,
    username: "",
    email: "",
  },
};

export default function loginReducer(
  state = initialState,
  { key, type, payload }: IReduxAction
): ILoginState {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        sending: true,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    case LOGGED_IN:
      return {
        ...state,
        sending: false,
        loggedIn: true,
        ...payload,
      };

    case LOGIN_REJECT:
      return {
        ...initialState,
        ...state,
        sending: false,
      };

    case REHYDRATE:
      return key === `${REDUX_KEY}-login`
        ? {
            ...state,
            ...payload?.fields,
            initialized: true,
          }
        : state;

    default:
      return state;
  }
}

import React from "react";
import classNames from "classnames";

import { IGenericObject } from "../../global";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";
import "./Button.scss";

export enum EnumButtonVariants {
  Primary = "primary",
  Secondary = "secondary",
  Orange = "orange",
  OrangeDark = "orange-dark",
}

interface IButtonProps extends IGenericObject {
  text?: string | number;
  variant?: EnumButtonVariants;
  width?: number;
  fontStyle?: React.CSSProperties;
  isLoading?: boolean;
  classes?: string | string[];
  textClasses?: string | string[];
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<IButtonProps> = ({
  text,
  variant,
  width,
  height,
  fontStyle,
  isLoading,
  classes,
  textClasses,
  children,
  ...otherProps
}) => {
  return (
    <button
      className={classNames("button", `button--${variant}`, classes)}
      style={{
        width,
        height,
      }}
      {...otherProps}
    >
      {children ? (
        children
      ) : (
        <span className={classNames("button__text", textClasses)} style={fontStyle}>
          {isLoading ? <Spinner /> : text}
        </span>
      )}
    </button>
  );
};

Button.defaultProps = {
  variant: EnumButtonVariants.Primary,
  fontSize: 12,
  isLoading: false,
};

export default Button;

import { AxiosResponse } from "axios";
import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { handleError, rejectApi } from "../../../utils/Functions";
import { setError, toastShow } from "../global/actions";
import { doLogout } from "../login/actions";
import { IRootState } from "../types";
import { listFulfilled, listLoadError } from "./actions";
import { loadListApi } from "./api";

import { IListLoadApi, IListResult, LIST_CHANGE_PAGE, LIST_LOAD } from "./types";

export function* listSaga(): any {
  yield all([
    yield takeLatest(LIST_LOAD, loadWorker),
    yield takeLatest(LIST_CHANGE_PAGE, loadWorker),
  ]);
}

function* loadWorker() {
  try {
    const {
      controller,
      page,
      search,
      filter,
      limit: limitState,
      params,
    }: IListLoadApi = yield select((state: IRootState) => state.list);
    const limit = controller === "images" ? 9999 : limitState;
    const response: AxiosResponse = yield call(loadListApi, {
      controller,
      page,
      search,
      limit,
      filter,
      params,
    });
    const data: IListResult = response.data;
    const { last_page: pages } = data.meta;
    yield put(listFulfilled(data.data, pages, page));
  } catch (err: any) {
    const error = rejectApi(err);
    const errResponse: AxiosResponse = err?.response;
    console.error("error", error);
    yield put(listLoadError());
    const errorMessage = handleError(errResponse, error);
    if (errResponse.status === 401) {
      //@TODO refresh token logic here...
      yield put(doLogout());
    }
    yield put(toastShow("error", errorMessage));
    yield put(
      setError({
        code: errResponse.status,
        message: errorMessage,
      })
    );
  }
}

import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Button from "../../components/Button";
import {
  actionHumanize,
  colDBHumanized,
  controllerHumanize,
  ignoreFields,
  disabledFields,
  crudEmptyFields,
  crudSpecialFields,
  crudNoEditFields,
  crudDisabledEditFields,
  crudNoRequiredFields,
  crudNoInsertFields,
  crudCustomData,
  ISpecialField,
  customActions,
  crudCanEdit,
  DATE_FORMAT,
  API_URL,
  DATE_TIME_FORMAT,
} from "../../config";

import { ICrud, IGenericObject, IRoute } from "../../global";
import { crudInsert, crudLoad, crudOptionsLoad, crudUpdate } from "../../state/ducks/crud/actions";
import { IRootState } from "../../state/ducks/types";
import {
  formatCrudDataView,
  formatCrudDataSave,
  renderError,
  getValue,
  formatCurrency,
} from "../../utils/Functions";

import "./Crud.scss";
import InputFile from "../../components/InputFile";
import MaskedInput from "../../components/MaskedInput";
import ApiClient from "../../services/ApiClient";

const Crud: FC<ICrud> = () => {
  const dispatch = useDispatch();
  const { controller, action = "", id }: IRoute = useParams();
  const { fields: humanFields } = useSelector((state: IRootState) => state.global);
  const { data, loading, sending, options: dynamicOptions } = useSelector((state: IRootState) => state.crud);

  const emptyFields = crudEmptyFields[controller] || {};
  const specialFields = crudSpecialFields[controller] || {};
  const noEditFields = crudNoEditFields[controller] || [];
  const noInsertFields = crudNoInsertFields[controller] || [];
  const disabledEditFields = crudDisabledEditFields[controller] || [];
  const noRequiredFields = crudNoRequiredFields[controller] || {};
  const customData = crudCustomData[controller] || {};
  const customActionButtons = customActions[controller] || [];
  const canEdit = crudCanEdit[controller] ?? true;

  const [crudData, setCrudData] = useState<IGenericObject>(emptyFields);
  const [errors, setErrors] = useState<IGenericObject>({});

  useEffect(() => {
    if (id) {
      dispatch(crudLoad(controller, id));
    }
    dispatch(crudOptionsLoad(controller));
  }, [controller, id]);

  useEffect(() => {
    if (id && data) {
      const dataFields: IGenericObject = {};
      Object.keys(emptyFields)?.forEach((field: any) => {
        if ((noEditFields.length > 0 && !noEditFields.includes(field)) || noEditFields.length === 0)
          dataFields[field] = formatCrudDataView(data[field], field, specialFields);
      });
      setCrudData(dataFields);
    }

    const errorsFields: IGenericObject = {};
    Object.keys(emptyFields).forEach((field) => {
      errorsFields[field] = {
        error: false,
        type: specialFields[field]?.type || typeof emptyFields[field],
      };
    });
    setErrors(errorsFields);
  }, [data, controller]);

  const handleChange = useCallback(
    (value, field: any) => {
      setCrudData({
        ...crudData,
        [field]: value,
      });
    },
    [data, crudData]
  );

  function uploadAdapter(loader: { file: Promise<File>; }) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then(async (file: File) => {
            const formData = new FormData()
            formData.append('image', file, file.name)
            try {
              
              const data = await ApiClient.post(`${API_URL}/${controller}/upload`, formData);
              return resolve(data.data);
            } catch (err) {
              return reject(err);
            }
          })
        })
      },
    }
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader)
    }
  }

  const handleSave = useCallback(() => {
    const erroredFields = { ...errors };
    let canSave = true;
    Object.keys(crudData).forEach((field: string) => {
      if (
        crudData[field] === "" &&
        typeof crudData[field] !== "boolean" &&
        !disabledFields.includes(field) &&
        !noRequiredFields[field]?.includes(action)
      ) {
        erroredFields[field].error = true;
        canSave = false;
      } else {
        erroredFields[field].error = false;
      }
    });
    if (canSave) {
      const formattedData = formatCrudDataSave(crudData, specialFields);
      if (id) {
        dispatch(crudUpdate(controller, id, formattedData));
      } else {
        dispatch(crudInsert(controller, formattedData));
      }
    }
    setErrors(erroredFields);
  }, [controller, id, crudData, errors, specialFields, action]);

  const renderFields = useCallback(() => {
    const fields: ReactNode[] = [];

    Object.keys(crudData)
      .filter((f) => !ignoreFields.includes(f))
      .forEach((field: any) => {

        if(noInsertFields.includes(field) && action === "insert") return

        const disabled =
          disabledFields.includes(field) ||
          (disabledEditFields.includes(field) && action === "edit");

        let fieldNode: ReactNode;
        if (specialFields[field]) {
          const {
            type: _type,
            mask,
            options = [],
            allowDecimals = true,
            otherAttributes = {},
            table,
          }: ISpecialField = specialFields[field];

          let type: string = ''

          if(_type==='setting') {
            switch (crudData['type']) {
              case 'HTML':
                type = 'editor'
                break;
              case 'BOOLEAN':
                type = 'checkbox'
                break;
              case 'NUMBER':
                type = 'integer'
                break;
              case 'DECIMAL':
                type = 'decimal'
                break;
              case 'DATE':
                type = 'date'
                specialFields[field].type = type
                crudData[field] = formatCrudDataView(data[field], field, specialFields);
                break;
              case 'DATETIME':
                type = 'datetime'
                specialFields[field].type = type
                crudData[field] = formatCrudDataView(data[field], field, specialFields);
                break;
            
              default:
                break;
            }
          } else {
            type = _type
          }

          switch (type) {
            case "select":
              fieldNode = (
                <select
                  name={field}
                  id={field}
                  disabled={!canEdit || disabled}
                  onChange={
                    disabled ? undefined : (e) => handleChange(e.currentTarget.value, field)
                  }
                  value={crudData[field]}
                  {...otherAttributes}
                >
                  <option key={`${field}_empty`} value={""}></option>
                  {options?.map((option) => (
                    <option key={`${field}_${option}`} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              );
              break;
            case "dynamic_select":
              fieldNode = (
                <select
                  name={field}
                  id={field}
                  disabled={!canEdit || disabled}
                  onChange={
                    disabled ? undefined : (e) => handleChange(e.currentTarget.value, field)
                  }
                  value={crudData[field] || ""} 
                  {...otherAttributes}
                >
                  <option key={`${field}_empty`} value={""}></option>
                  {dynamicOptions[table]?.map((option: any) => (
                    <option key={`${field}_${option.id}`} value={option.id}>
                      {option.value}
                    </option>
                  ))}
                  
                </select>
              );
              break;
            case "decimal":
              fieldNode = (
                <CurrencyInput
                  disabled={disabled || !canEdit}
                  id={field}
                  name={field}
                  allowDecimals={allowDecimals}
                  decimalSeparator={","}
                  groupSeparator={"."}
                  onValueChange={(value) => handleChange(value, field)}
                  value={allowDecimals ? crudData[field] : parseInt(crudData[field] || 0)}
                  decimalScale={2}
                />
              );
              break;
            case "image":
              fieldNode = <img width="200" src={crudData[field]} />;
              break;
            case "link":
              fieldNode = (
                <a className="link" href={crudData[field]} rel="noreferrer" target="_blank">
                  {crudData[field]}
                </a>
              );
              break;
            case "accounts":
              if(crudData[field]){
                fieldNode = (
                    <table style={{width: '250px'}}>
                    {crudData[field].map((account: { id: any, balance: number; accountType: { code: string } }) => (
                      <tr key={account.id}>
                        <td><strong>{account?.accountType?.code}</strong>:</td>
                        <td align="left">{ formatCurrency(account?.balance, 'real')}</td>
                      </tr>
                    ))}
                    </table>
                );
              }
              break;
            case "mask":
              fieldNode = (
                <MaskedInput
                  getMask={mask}
                  id={field}
                  disabled={!canEdit}
                  name={field}
                  onChange={(e) => handleChange(e.target.value, field)}
                  value={crudData[field]}
                />
              );
              break;
            case "textarea":
              fieldNode = (
                <textarea
                  id={field}
                  disabled={!canEdit}
                  name={field}
                  value={crudData[field]}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleChange(e.target.value, field)
                  }
                />
              );
              break;
            case "password":
              fieldNode = (
                <input
                  autoComplete="new-password"
                  disabled={disabled || !canEdit}
                  type="password"
                  name={field}
                  id={field}
                  value={crudData[field]}
                  onChange={
                    disabled ? undefined : (e) => handleChange(e.currentTarget.value, field)
                  }
                />
              );
              break;
            case "integer":
              fieldNode = (
                <input
                  disabled={disabled || !canEdit}
                  type="number"
                  name={field}
                  id={field}
                  value={crudData[field]}
                  onChange={
                    disabled ? undefined : (e) => handleChange(e.currentTarget.value, field)
                  }
                />
              );
              break;
            case "uploader":
              fieldNode = (
                <InputFile
                  label={"Selecionar"}
                  id={field}
                  disabled={!canEdit}
                  onFileSelect={(file) => handleChange(file, field)}
                  fileName={crudData[field]?.name}
                />
              );
              break;
            case "datetime":
              fieldNode = (
                <DatePicker
                  showTimeSelect
                  disabled={disabled || !canEdit}
                  selected={crudData[field] || ""}
                  onChange={(date) => handleChange(date, field)}
                  dateFormat={DATE_TIME_FORMAT}
                  locale="pt-br"
                  id={field}
                  name={field}
                />
              );
              break;
            case "date":
              fieldNode = (
                <DatePicker
                  disabled={disabled || !canEdit}
                  selected={crudData[field] || ""}
                  onChange={(date) => handleChange(date, field)}
                  locale="pt-br"
                  dateFormat={DATE_FORMAT}
                  id={field}
                  name={field}
                />
              );
              break;
            case "checkbox":
              fieldNode = (
                <input
                  autoComplete="off"
                  disabled={disabled || !canEdit}
                  type="checkbox"
                  checked={crudData[field]}
                  name={field}
                  id={field}
                  onChange={disabled ? undefined : () => handleChange(!crudData[field], field)}
                />
              );
              break;
              case "editor":
                fieldNode = (
                  <CKEditor
                      id={field}
                      name={field}
                      key={field}
                      config={{
                        extraPlugins: [uploadPlugin],
                        removePlugins: ['Heading'],
                        mediaEmbed: {
                          previewsInData: true,
                        },
                        link: {
                          addTargetToExternalLinks: true,
                          defaultProtocol: 'http://',
                        },
                        language: 'pt-br'
                      }}
                      editor={ClassicEditor}
                      data={crudData[field] || ""}
                      onReady={(editor: any) => {
                        if(crudData[field]) {
                          editor.setData(crudData[field])
                        }
                      }}
                      onBlur={(event: any, editor: { getData: () => any; }) => {
                        const data = editor.getData()
                        handleChange(data, field)
                      }}
                    />
                );
                break;
                
                default: 
                  fieldNode = (
                    <input
                      autoComplete="off"
                      disabled={disabled || !canEdit}
                      type="text"
                      name={field}
                      id={field}
                      value={getValue(crudData[field])}
                      onChange={disabled ? undefined : (e) => handleChange(e.currentTarget.value, field)}
                    />
                  );
          }
        } else {
          fieldNode = (
            <input
              autoComplete="off"
              disabled={disabled || !canEdit}
              type="text"
              name={field}
              id={field}
              value={getValue(crudData[field])}
              onChange={disabled ? undefined : (e) => handleChange(e.currentTarget.value, field)}
            />
          );
        }
        fields.push(
          <div className="r" key={field}>
            <label htmlFor={field}>{colDBHumanized(field, humanFields)}</label>
            {fieldNode}
            {renderError(errors[field])}
          </div>
        );
      });

    return fields;
  }, [crudData, errors, humanFields]);

  const renderCustomData = useCallback(() => {
    const fields: ReactNode[] = [];

    for (const field in customData) {
      fields.push(
        <div className="r" key={field}>
          <label htmlFor={field}>{customData[field].label}</label>
          <input
            readOnly
            type="text"
            name={field}
            id={field}
            value={data[field] && customData[field].value(data[field])}
          />
        </div>
      );
    }

    return fields;
  }, [customData, data]);

  const history = useHistory();

  return (
    <div className="crud">
      <div className="head">
        <span className="title">
          {controllerHumanize(controller)} { canEdit &&  `- ${actionHumanize[action]}`}
          {id && ` - ID ${id}`}
        </span>
        <div className="buttons">
          <Button
            key={`actionButton-voltar`}
            disabled={loading || sending}
            onClick={() => history.push(`/${controller}`)}
          >
            <span className="material-icons">chevron_left</span>
            Voltar
          </Button>
          {customActionButtons?.map(
            ({ icon, condition, action, buttonTitle }, i) =>
              buttonTitle &&
              condition &&
              condition(data) && (
                <Button
                  key={`actionButton${i}`}
                  disabled={loading || sending}
                  onClick={() => action(dispatch, crudData)}
                >
                  <span className="material-icons">{icon}</span>
                  {buttonTitle}
                </Button>
              )
          )}
          {(canEdit || action === "insert") && !loading && (
            <Button
              disabled={loading || sending}
              text={loading || sending ? "Carregando..." : "Salvar"}
              onClick={() => handleSave()}
            />
          )}
        </div>
      </div>
      <div className="form">
        {action === "edit" && !loading && (
          <div className={`customData_${controller}`}>{renderCustomData()}</div>
        )}
        {loading ? <p className="loading">Carregando...</p> : renderFields()}
      </div>
    </div>
  );
};

export default Crud;

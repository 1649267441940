import { IReduxAction } from "../../../global";
import {
  WITHDRAW_CONCLUDE,
  IWithdrawState,
  WITHDRAW_SUCCESS,
  WITHDRAW_ERROR,
} from "./types";

export const initialState: IWithdrawState = {
  loading: false,
  error: {
    code: 0,
    message: "",
  },
};

export default function withdrawReducer(
  state = initialState,
  { type }: IReduxAction
): IWithdrawState {
  switch (type) {
    case WITHDRAW_CONCLUDE:
      return {
        ...state,
        loading: true,
      };
    case WITHDRAW_SUCCESS:
    case WITHDRAW_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
}

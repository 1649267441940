import React from "react";
import ReactDOM from "react-dom";
import "./styles/root.scss";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { ToastProvider } from "react-toast-notifications";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastProvider placement="bottom-right">
        <App />
      </ToastProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

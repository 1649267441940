import React, { useCallback, useState } from "react";
import { IGenericObject } from "../../global";
import InputMask from "react-input-mask";

interface GetMask {
  (value?: string): string;
}

interface IMaskedInput extends IGenericObject {
  getMask: string | GetMask;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaskedInput = ({ getMask, onChange, ...otherAttributes }: IMaskedInput) => {
  const [inputMask, setInputMask] = useState<string>(
    typeof getMask === "string" ? getMask : getMask()
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof getMask !== "string") {
        setInputMask(getMask(e.target.value));
      }
      if (onChange) onChange(e);
    },
    [getMask, onChange]
  );

  return <InputMask mask={inputMask} onChange={handleChange} {...otherAttributes} />;
};

export default MaskedInput;

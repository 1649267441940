import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { IRootState } from "../../../state/ducks/types";
import { IGenericObject } from "../../../global";
import { ROUTE_LOGIN } from "../../../config";

const AuthRoute: React.FC<IGenericObject> = ({ children, ...otherAttributes }) => {
  const { initialized, loggedIn } = useSelector((state: IRootState) => state.login);

  const render = useCallback(
    (props) => {
      const { location } = props;

      if (!initialized) {
        return null;
      }

      if (loggedIn && initialized) {
        return children;
      }

      return (
        <Redirect
          to={{
            pathname: ROUTE_LOGIN,
            state: { from: location },
          }}
        />
      );
    },
    [loggedIn, children, initialized]
  );

  return <Route {...otherAttributes}>{render}</Route>;
};

export default AuthRoute;

import ApiClient from "../../../services/ApiClient";
import { IListLoadApi } from "./types";
import querystring from "querystring";
import _ from "lodash";

export const loadListApi = ({ controller, params, filter, ...otherProps }: IListLoadApi) => {
  const qs = querystring.stringify(
    _.omitBy({ ...otherProps, ...filter, ...params }, (v) => v === "")
  );
  return ApiClient.get(`${controller}?${qs}`);
};

import { IGenericObject } from "../../../global";

export const CRUD_LOAD = "CRUD_LOAD";
export const CRUD_LOAD_FULFILLED = "CRUD_LOAD_FULFILLED";
export const CRUD_INSERT = "CRUD_INSERT";
export const CRUD_UPDATE = "CRUD_UPDATE";
export const CRUD_DELETE = "CRUD_DELETE";
export const CRUD_SUCCESS = "CRUD_SUCCESS";
export const CRUD_ERROR = "CRUD_ERROR";

export const CRUD_OPTIONS_LOAD = "CRUD_OPTIONS_LOAD";
export const CRUD_OPTIONS_LOAD_FULFILLED = "CRUD_OPTIONS_LOAD_FULFILLED";
export const CRUD_OPTIONS_LOAD_ERROR = "CRUD_OPTIONS_LOAD_ERROR";

export interface IApiCRUD {
  controller: string;
  id?: string;
  data?: any;
  formData?: FormData;
}

export interface ICrudState {
  loading: boolean;
  sending: boolean;
  success?: boolean;
  data: IGenericObject;
  options: IGenericObject;
}

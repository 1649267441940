import { takeLatest, all, call, put } from "redux-saga/effects";
import { rejectApi } from "../../../utils/Functions";
import {  toastShow } from "../global/actions";
import { listLoad } from "../list/actions";
import { depositError, depositSuccess } from "./actions";
import { depositApproveApi, depositFirstApi } from "./api";
import { DEPOSIT_APPROVE, DEPOSIT_FIRST } from "./types";

export function* depositSaga(): any {
  yield all([yield takeLatest(DEPOSIT_APPROVE, depositApproveWorker)]);
  yield all([yield takeLatest(DEPOSIT_FIRST, depositFirstWorker)]);
}


function* depositApproveWorker({ payload: { id } }: any) {
  try {
    yield call(depositApproveApi, id);
    yield put(toastShow("success", 'Depósito aprovado com sucesso!'));
    yield put(depositSuccess());
    yield put(listLoad({ controller: 'deposits' }));
  } catch (err) {
    const error = rejectApi(err);
    yield put(toastShow("error", error));
    yield put(depositError());
  }
}

function* depositFirstWorker({ payload: { id } }: any) {
  try {
    yield call(depositFirstApi, id);
    yield put(toastShow("success", 'Jogador removido com sucesso!'));
    yield put(depositSuccess());
    yield put(listLoad({ controller: 'deposits' }));
  } catch (err) {
    const error = rejectApi(err);
    yield put(toastShow("error", error));
    yield put(depositError());
  }
}
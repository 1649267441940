import { IReduxAction } from "../../../global";
import {
  DEPOSIT_APPROVE,
  DEPOSIT_ERROR,
  DEPOSIT_SUCCESS,
  IDepositState,
} from "./types";

export const initialState: IDepositState = {
  loading: false,
  error: {
    code: 0,
    message: "",
  },
};

export default function depositReducer(
  state = initialState,
  { type }: IReduxAction
): IDepositState {
  switch (type) {
    case DEPOSIT_APPROVE:
      return {
        ...state,
        loading: true,
      };
    case DEPOSIT_SUCCESS:
    case DEPOSIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
}

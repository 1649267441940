import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { v4 } from "uuid";

import { toastClear } from "../../state/ducks/global/actions";
import { IRootState } from "../../state/ducks/types";

const ToastManager: FC = () => {
  const dispatch = useDispatch();
  const { addToast, removeToast } = useToasts();

  const { appearance, message } = useSelector((state: IRootState) => state.global);

  useEffect(() => {
    if (message) {
      const id = `notify_${appearance}_${v4()}`;
      addToast(
        <div
          className="wrapToast"
          onClick={() => {
            removeToast(id);
          }}
        >
          <p>{message}</p>
        </div>,
        { appearance, autoDismiss: true }
      );
    }
    dispatch(toastClear());
  }, [dispatch, addToast, removeToast, appearance, message]);

  return null;
};

export default ToastManager;

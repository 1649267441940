import { AxiosResponse } from "axios";
import { takeLatest, all, call, put } from "redux-saga/effects";
import Cookie, { ACCESS_COOKIE, REFRESH_COOKIE } from "../../../services/Cookie";
import { rejectApi } from "../../../utils/Functions";
import { toastShow } from "../global/actions";
import { loggedIn, loginReject } from "./actions";
import { loginApi } from "./api";

import { LOGIN, LOGOUT, ILoginAction } from "./types";

export function* loginSaga(): any {
  yield all([yield takeLatest(LOGIN, loginWorker), yield takeLatest(LOGOUT, logoutWorker)]);
}

function* loginWorker({ payload }: ILoginAction) {
  try {
    const response: AxiosResponse = yield call(loginApi, payload);
    const {
      data: {
        user,
        token: { token, type, expires_at },
      },
    } = response;
    Cookie.saveAuthenticationCookie(token, type, expires_at);
    yield put(
      loggedIn({
        user,
        token,
        expires_at,
      })
    );
  } catch (err) {
    const error = rejectApi(err);
    console.error("error", error);
    yield put(toastShow("error", error));
    yield put(loginReject(error));
  }
}

function logoutWorker() {
  try {
    Cookie.deleteCookie(ACCESS_COOKIE);
    Cookie.deleteCookie(REFRESH_COOKIE);
    localStorage.clear();
    window.location.href = "/";
  } catch (error) {
    console.error("error", error);
  }
}

import { applyMiddleware, createStore, compose, Middleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";

import { rootSaga, rootReducers } from "./ducks";

const bindMiddleware = (...middleware: Middleware[]) =>
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware);

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducers, compose(bindMiddleware(sagaMiddleware)));

if (process.env.REACT_APP_ENABLE_PERSIST === "true") persistStore(store);

sagaMiddleware.run(rootSaga);

import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import storage from "redux-persist/lib/storage";

import { REDUX_KEY } from "../../config";

import { loginSaga } from "./login/saga";
import { listSaga } from "./list/saga";
import { crudSaga } from "./crud/saga";
import { globalSaga } from "./global/saga";
import { depositSaga } from "./deposit/saga";

import loginReducer from "./login/reducer";
import globalReducer from "./global/reducer";
import listReducer from "./list/reducer";
import crudReducer from "./crud/reducer";
import depositReducer from "./deposit/reducer"
import withdrawReducer from "./withdraw/reducer";
import { withdrawSaga } from "./withdraw/saga";

export function* rootSaga() {
  yield all([loginSaga(), listSaga(), crudSaga(), globalSaga(), depositSaga(), withdrawSaga()]);
}

const persistedLogin = persistReducer(
  {
    key: `${REDUX_KEY}-login`,
    storage,
    whitelist: ["loggedIn", "token", "refreshToken", "user"],
  },
  loginReducer
);

const persistedGlobal = persistReducer(
  {
    key: `${REDUX_KEY}-global`,
    storage,
    whitelist: ["fields"],
  },
  globalReducer
);

export const rootReducers = combineReducers({
  login: persistedLogin,
  global: persistedGlobal,
  list: listReducer,
  crud: crudReducer,
  deposit: depositReducer,
  withdraw: withdrawReducer,
});

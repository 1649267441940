export const LOGIN = "LOGIN";
export const LOGGED_IN = "LOGGED_IN";
export const LOGIN_REJECT = "LOGIN_REJECT";

export const LOGOUT = "LOGOUT";

export interface ILoginState {
  initialized: boolean;
  sending: boolean;
  loggedIn: boolean;
  token: string;
  refreshToken: string;
  user: ILoginUsuario;
}

export interface ILoginAction {
  type: typeof LOGIN;
  payload: ILoginService;
}

export interface ILoggedInAction {
  type: typeof LOGGED_IN;
  payload: ILoginApi;
}

export interface ILoginService {
  username: string;
  password: string;
}

export interface ILoginUsuario {
  id: number;
  username: string;
  email?: string;
}

export interface ILoginApi {
  user: ILoginUsuario;
  token: string;
  expires_at: Date;
}

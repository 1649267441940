import { REHYDRATE } from "redux-persist";
import { AxiosResponse } from "axios";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { rejectApi } from "../../../utils/Functions";
import { setFields, toastShow } from "../global/actions";
import { getFieldsApi } from "./api";

import { GET_FIELDS } from "./types";
import Cookie from "../../../services/Cookie";

export function* globalSaga(): any {
  yield all([
    yield takeLatest(GET_FIELDS, getFieldsWorker), 
    yield takeLatest(REHYDRATE, persistRehydrate)
  ]);
  
}

function* getFieldsWorker() {
  try {
    const { data: fields }: AxiosResponse = yield call(getFieldsApi);
    yield put(setFields(fields));
  } catch (err: any) {
    const error = rejectApi(err);
    console.error("error", error);
    yield put(toastShow("error", error));
  }
}

function persistRehydrate({ payload }: any) {
  const cookieAuth = Cookie.getAuthenticationCookie();
  const cookieReauth = Cookie.getReAuthenticationCookie();
  if (!cookieAuth && cookieReauth && payload?.loggedIn) {
    localStorage.clear();
    window.location.reload();
  }
}

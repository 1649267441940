import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { redirect, storeRoute } from "../../state/ducks/global/actions";
import { crudLoadFulfilled } from "../../state/ducks/crud/actions";
import { IRootState } from "../../state/ducks/types";

const RedirectManager: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { redirectRoute } = useSelector((state: IRootState) => state.global);

  useEffect(() => {
    if (redirectRoute) {
      history.push(`/${redirectRoute}`);
      dispatch(redirect());
    }
  }, [dispatch, history, redirectRoute]);

  useEffect(() => {
    dispatch(crudLoadFulfilled({}));
    dispatch(storeRoute(location.pathname));
  }, [location]);

  return null;
};

export default RedirectManager;

import { IReduxAction } from "../../../global";
import {
  WITHDRAW_CANCEL,
  WITHDRAW_CONCLUDE, WITHDRAW_ERROR, WITHDRAW_REFUND, WITHDRAW_SUCCESS,
} from "./types";

export const withdrawApprove = (id: number): IReduxAction => ({
  type: WITHDRAW_CONCLUDE,
  payload: {
    id,
  },
});

export const withdrawCancel = (id: number): IReduxAction => ({
  type: WITHDRAW_CANCEL,
  payload: {
    id,
  },
});

export const withdrawRefund = (id: number): IReduxAction => ({
  type: WITHDRAW_REFUND,
  payload: {
    id,
  },
});

export const withdrawSuccess = (): IReduxAction => ({
  type: WITHDRAW_SUCCESS,
});

export const withdrawError = (): IReduxAction => ({
  type: WITHDRAW_ERROR,
});
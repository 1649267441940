import { IReduxAction } from "../../../global";
import {
  LOGGED_IN,
  LOGIN,
  LOGIN_REJECT,
  LOGOUT,
  ILoggedInAction,
  ILoginAction,
  ILoginApi,
} from "./types";

export const doLogin = (username: string, password: string): ILoginAction => ({
  type: LOGIN,
  payload: {
    username,
    password,
  },
});

export const doLogout = (): IReduxAction => ({
  type: LOGOUT,
});

export const loggedIn = (payload: ILoginApi): ILoggedInAction => ({
  type: LOGGED_IN,
  payload,
});

export const loginReject = (message: string): IReduxAction => ({
  type: LOGIN_REJECT,
  payload: {
    message,
  },
});

import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { parseISO } from "date-fns/esm";
import ptBR from "date-fns/locale/pt-BR";

import {
  colDBHumanized,
  customColData,
  dateCols,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  decimalCols,
  humanizeErrorMessage,
  imageCols,
  omitColumns,
  phoneCols,
} from "../config";
import { IGenericObject } from "../global";

export const size = (obj: IGenericObject) => Object.keys(obj).length;

export const rejectApi = (err: any) =>
  humanizeErrorMessage(
    err?.response?.data?.status?.message || err?.response?.data.message || err.message || err
  );

export const handleError = (errResponse: AxiosResponse, error: string) =>
  errResponse.status === 401 ? "Login expirado" : error;

export const getTh = (col: string, fields?: IGenericObject) => colDBHumanized(col, fields);

export const getTd = (col: string, val: string) => {
  if (dateCols.includes(col)) {
    return val
      ? format(new Date(val), DATE_TIME_FORMAT, {
          locale: ptBR,
        })
      : "-";
  }
  if (decimalCols.includes(col)) {
    return formatCurrency(parseFloat(val), "real");
  }
  if (typeof val === "boolean") {
    return val ? "Sim" : "Não";
  }
  if (imageCols.includes(col)) {
    return val ? <img src={val} height="70" /> : <></>
  }

  if (phoneCols.includes(col)) {
    return val ? <a href={`https://api.whatsapp.com/send?phone=55${val.replace(/\D/g, "")}`} target="_blank" rel="noreferrer">{val}</a> : <></>
  }
  return val;
};

export const getValue = (value: any = "") => value;

export const formatCurrency = (value: number, currency?: string) => {
  switch (currency) {
    case "real":
      return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(value);
    default:
      return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(value);
  }
};

export const formatDecimal = (value: string): number => {
  let valor = value.replace(".", "");
  valor = valor.replace(",", ".");
  return parseFloat(valor);
};

export const padCounter = (value: number) => value.toString().padStart(2, "0");

export const renderTh = (controller: string, value: any, key: number, fields?: IGenericObject) => {
  const omitColumn = [...(omitColumns[controller] || []), ...omitColumns["all"]];
  const isObject = typeof value[1] === "object";
  const customColDataAction = customColData[controller] && customColData[controller][value[0]];
  if (
    !omitColumn.includes(value[0]) &&
    (!isObject || value[1] === null || customColDataAction !== undefined)
  ) {
    return <th key={`th_${key}`}>{getTh(value[0], fields)}</th>;
  }
};

export const renderTd = (controller: string, value: any, key: number) => {
  const omitColumn = [...(omitColumns[controller] || []), ...omitColumns["all"]];
  const isObject = typeof value[1] === "object";
  const customColDataAction = customColData[controller] && customColData[controller][value[0]];
  if (
    !omitColumn.includes(value[0]) &&
    (!isObject || value[1] === null || customColDataAction !== undefined)
  ) {
    return (
      <td key={`td_${key}`} data-col={value[0]}>
        {customColDataAction ? customColDataAction(value[1]) : getTd(value[0], value[1])}
      </td>
    );
  }
};

export const arrToObject = (data: object | string[], initialValue: any = undefined) =>
  Object.assign(
    {},
    ...(data instanceof Array ? data : Object.keys(data)).map((el) => ({ [el]: initialValue }))
  );

export const renderError = ({ error, type, message }: IGenericObject = {}) => {
  if (error) {
    let errorMessage = "";
    switch (type) {
      case "select":
        errorMessage = message || "Selecione uma opção";
        break;
      default:
        errorMessage = message || "Preencha este campo";
    }
    return <label className={"error"}>{errorMessage}</label>;
  }
};

export const intersectKeys = (first: object, ...rest: object[]) => {
  const restKeys = rest.map((o) => Object.keys(o));
  return Object.fromEntries(
    Object.entries(first).filter((entry) => restKeys.every((rk) => rk.includes(entry[0])))
  );
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatCrudDataSave = (data: IGenericObject, specialFields: IGenericObject) => {
  const formattedData: IGenericObject = {};
  Object.keys(data).forEach((field) => {
    const value = data[field];
    switch (specialFields[field]?.type) {
      case "date":
        formattedData[field] = value
          ? format(value, DATE_FORMAT, {
              locale: ptBR,
            })
          : null;
        break;
      case "datetime":
        formattedData[field] = value
          ? format(value, DATE_TIME_FORMAT, {
              locale: ptBR,
            })
          : null;
        break;
      default:
        if (typeof value === "boolean") {
          formattedData[field] = value ? 1 : 0;
        } else {
          formattedData[field] = value;
        }
    }
  });
  return formattedData;
};

export const formatCrudDataView = (value: any, field: any, specialFields: IGenericObject) => {
  switch (specialFields[field]?.type) {
    case "date":
    case "datetime":
      if (value) {
        return parseISO(value);
      }
      return null;
    case "uploader":
      return "";
    default:
      return value;
  }
};

export const prepareData = (data: IGenericObject, specialFields: IGenericObject = {}) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value || specialFields[key]?.type === "date" || specialFields[key]?.type === "checkbox" || specialFields[key]?.type === "setting") {
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else {
        formData.append(key, value ?? "");
      }
    }
  });
  return formData;
};

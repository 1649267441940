import classNames from "classnames";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { MENU } from "../../config";

import "./Menu.scss";

const Icon: FC<{ icon: string }> = ({ icon }) => {
  switch (icon) {
    case "home":
      return <span className="material-icons">home</span>;
    case "players":
      return <span className="material-icons">face</span>;
    case "games":
      return <span className="material-icons">casino</span>;
    case "deposits":
      return <span className="material-icons">paid</span>;
    case "deposits-first":
      return <span className="material-icons">exposure_plus_1</span>;
    case "withdraws":
      return <span className="material-icons">money_off</span>;
    case "credits":
      return <span className="material-icons">credit_score</span>;
    case "exchanges":
      return <span className="material-icons">swap_horiz</span>;
    case "transactions":
      return <span className="material-icons">account_balance</span>;
    case "chat-phrases":
      return <span className="material-icons">chat</span>;
    case "users":
      return <span className="material-icons">person</span>;
    case "bots":
      return <span className="material-icons">smart_toy</span>;
    case "banners":
      return <span className="material-icons">image</span>;
    case "settings":
      return <span className="material-icons">settings</span>;
    case "notification-messages":
      return <span className="material-icons">notifications</span>;
    default:
      return <></>;
  }
};

const Menu: FC = () => {
  const location = useLocation();
  const routerParams = location.pathname.split("/");

  return (
    <div className="menu">
      {MENU.map(({ path, icon }, i) => (
        <Link
          to={path}
          key={i}
          className={classNames({
            selected: `/${routerParams[1]}` === path,
          })}
        >
          <Icon icon={icon} />
        </Link>
      ))}
    </div>
  );
};

export default Menu;

import Button from "../Button";
import { controllerHumanize } from "../../config";

import "./Head.scss";
import { FC, useCallback } from "react";
import { useHistory } from "react-router";

interface IHead {
  hasInsertAction: boolean;
  controller: string;
}

const Head: FC<IHead> = ({ hasInsertAction, controller, children }) => {
  const history = useHistory();

  const handleClickNew = useCallback(() => {
    history.push(`/${controller}/insert`);
  }, [controller]);

  return (
    <div className="head">
      <span className="title">Gerenciar {controllerHumanize(controller)}</span>
      <div className="buttons">
        {children}
        {hasInsertAction && (
          <Button key={`new_${controller}`} text="Criar Novo" onClick={() => handleClickNew()} />
        )}
      </div>
    </div>
  );
};

export default Head;

import { FC } from "react";

import "./Error.scss";

interface IError {
  code?: number;
  message?: string;
}

const Error: FC<IError> = ({ code, message }) => (
  <div className="error">
    <h1 className="title">Erro {code}</h1>
    <div className="content">
      <p>Mensagem</p>
      <pre>{message}</pre>
    </div>
  </div>
);

export default Error;

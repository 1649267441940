import ApiClient from "../../../services/ApiClient";
import { IApiCRUD } from "./types";

export const crudLoadApi = ({ controller, id }: IApiCRUD) => ApiClient.get(`${controller}/${id}`);

export const crudInsertApi = ({ controller, formData }: IApiCRUD) =>
  ApiClient.post(`${controller}`, formData);

export const crudUpdateApi = ({ controller, id, formData }: IApiCRUD) =>
  ApiClient.patch(`${controller}/${id}`, formData);

export const crudDeleteApi = ({ controller, id }: IApiCRUD) =>
  ApiClient.delete(`${controller}/${id}`);

  export const crudOptionsApi = ({ controller }: IApiCRUD) =>
  ApiClient.get(`${controller}/options`);

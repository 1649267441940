import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { handleError, prepareData, rejectApi } from "../../../utils/Functions";
import { redirect, setError, toastShow } from "../global/actions";
import { listLoad } from "../list/actions";
import { CRUD_LOAD, CRUD_INSERT, CRUD_UPDATE, CRUD_DELETE, CRUD_OPTIONS_LOAD } from "./types";
import { crudInsertApi, crudUpdateApi, crudDeleteApi, crudLoadApi, crudOptionsApi } from "./api";
import { crudError, crudLoadFulfilled, crudOptionsLoadFulfilled } from "./actions";
import { AxiosResponse } from "axios";
import { crudSpecialFields } from "../../../config";
import { doLogout } from "../login/actions";
import { IRootState } from "../types";

export function* crudSaga(): any {
  yield all([
    yield takeLatest(CRUD_LOAD, crudLoadWorker),
    yield takeLatest(CRUD_INSERT, crudInsertWorker),
    yield takeLatest(CRUD_UPDATE, crudUpdateWorker),
    yield takeLatest(CRUD_DELETE, crudDeleteWorker),
    yield takeLatest(CRUD_OPTIONS_LOAD, crudOptionsWorker),
  ]);
}

function* crudLoadWorker({ payload: { controller, id } }: any) {
  try {
    const { data }: AxiosResponse = yield call(crudLoadApi, { controller, id });
    yield put(crudLoadFulfilled(data));
  } catch (err: any) {
    const error = rejectApi(err);
    const errResponse: AxiosResponse = err?.response;
    console.error("error", error);
    const errorMessage = handleError(errResponse, error);
    if (errResponse.status === 401) {
      //@TODO refresh token logic here...
      yield put(doLogout());
    }
    yield put(toastShow("error", error));
    yield put(
      setError({
        code: errResponse.status,
        message: errorMessage,
      })
    );
  }
}

function* crudInsertWorker({ payload: { controller, data } }: any) {
  let error = "";
  try {
    const formData = prepareData(data, crudSpecialFields[controller]);
    yield call(crudInsertApi, { controller, formData });
    yield put(redirect(controller));
  } catch (err: any) {
    error = rejectApi(err);
    const errResponse: AxiosResponse = err?.response;
    console.error("error", error);
    yield put(crudError());
    const errorMessage = handleError(errResponse, error);
    if (errResponse.status === 401) {
      //@TODO refresh token logic here...
      yield put(doLogout());
    }
    yield put(toastShow("error", errorMessage));
  } finally {
    if (error === "") yield put(listLoad({ controller }));
  }
}

function* crudUpdateWorker({ payload: { controller, id, data } }: any) {
  let error = "";
  try {
    const formData = prepareData(data, crudSpecialFields[controller]);
    const { page } = yield select((state: IRootState) => state.list);
    yield call(crudUpdateApi, { controller, id, formData });
    yield put(redirect(`${controller}/${page}`));
  } catch (err: any) {
    error = rejectApi(err);
    const errResponse: AxiosResponse = err?.response;
    console.error("error", error);
    yield put(crudError());
    const errorMessage = handleError(errResponse, error);
    if (errResponse.status === 401) {
      //@TODO refresh token logic here...
      yield put(doLogout());
    }
    yield put(toastShow("error", errorMessage));
  } finally {
    if (error === "") yield put(listLoad({ controller }));
  }
}

function* crudDeleteWorker({ payload: { controller, id, doRedirect } }: any) {
  try {
    const { page } = yield select((state: IRootState) => state.list);
    yield call(crudDeleteApi, { controller, id });
    if (doRedirect) {
      yield put(redirect(`${controller}/${page}`));
    } else {
      yield put(listLoad({ controller }));
    }
  } catch (err) {
    const error = rejectApi(err);
    console.error("error", error);
    yield put(crudError());
    yield put(toastShow("error", error));
  }
}

function* crudOptionsWorker({ payload: { controller } }: any) {
  try {
    const { data }: AxiosResponse = yield call(crudOptionsApi, { controller });
    yield put(crudOptionsLoadFulfilled(data));
  } catch (err: any) {
    //console.log(err)
  }
}
import { FC } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { IRootState } from "./state/ducks/types";
import {
  ROUTE_LOGIN,
  ROUTE_HOME,
  ROUTE_LIST,
  ROUTE_CRUD,
  ROUTE_CUSTOM,
  APP_VERSION,
} from "./config";

import Login from "./containers/Login";
import AuthRoute from "./components/Auth/AuthRoute";
import Welcome from "./containers/Welcome";
import RedirectManager from "./components/RedirectManager";
import RouteManager from "./containers/RouteManager";
import Header from "./components/Header";
import ToastManager from "./containers/ToastManager";
import FieldsManager from "./containers/FieldsManager";
import { IRouteType } from "./containers/RouteManager/RouteManager";

const App: FC = () => {
  const { loggedIn } = useSelector((state: IRootState) => state.login);

  return (
    <Router>
      <ToastManager />
      <FieldsManager />
      {loggedIn && <Header />}
      <div
        className={classNames("wrapper", {
          loggedIn,
        })}
      >
        <div className="content">
          <RedirectManager />
          <Switch>
            <Route path={ROUTE_LOGIN}>
              <Login />
            </Route>
            <AuthRoute exact path={ROUTE_HOME}>
              <Welcome />
            </AuthRoute>
            <AuthRoute path={ROUTE_CUSTOM}>
              <RouteManager />
            </AuthRoute>
            <AuthRoute path={ROUTE_CRUD}>
              <RouteManager crud={true} />
            </AuthRoute>
            <AuthRoute path={ROUTE_LIST}>
              <RouteManager type={IRouteType.List} />
            </AuthRoute>
          </Switch>
        </div>
      </div>
      <p
        className={classNames("appVersion", {
          loggedIn,
        })}
      >
        {APP_VERSION}
      </p>
    </Router>
  );
};

export default App;

import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFields } from "../../state/ducks/global/actions";

import { IRootState } from "../../state/ducks/types";

const FieldsManager: FC = () => {
  const dispatch = useDispatch();

  const { initialized, fields } = useSelector((state: IRootState) => state.global);

  useEffect(() => {
    if (initialized && Object.keys(fields).length === 0) {
      dispatch(getFields());
    }
  }, [initialized, fields]);

  return null;
};

export default FieldsManager;

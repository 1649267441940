export const IS_DEV = process.env.NODE_ENV === "development";

export const API_URL = process.env.REACT_APP_API_URL || "";

export const IS_SECURE = API_URL?.indexOf("https") !== -1;

export const PUBLIC_PATH = "/";

export const BROWSER = () => (typeof window !== "undefined" && window.navigator.userAgent) || "";
export const IS_CHROME = () => BROWSER().indexOf("Chrome") !== -1;

export const APP_VERSION = "0.01";
export const APP_NAME = "Bingotine";
export const REDUX_KEY = `${APP_NAME}-${APP_VERSION}`;
export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_TIME_FORMAT = "dd/MM/yyyy HH:mm";
export const DATE_TIME_FORMAT_S = "dd/MM/yyyy HH:mm:ss";

export const MENU = [
  {
    path: "/",
    icon: "home",
  },
  {
    path: "/players",
    icon: "players",
  },
  {
    path: "/games",
    icon: "games",
  },
  {
    path: "/deposits",
    icon: "deposits",
  },
  {
    path: "/deposits-first",
    icon: "deposits-first",
  },
  {
    path: "/credits",
    icon: "credits",
  },
  {
    path: "/withdraws",
    icon: "withdraws",
  },
  {
    path: "/exchanges",
    icon: "exchanges",
  },
  {
    path: "/transactions",
    icon: "transactions",
  },
  {
    path: "/chat-phrases",
    icon: "chat-phrases",
  },
  {
    path: "/users",
    icon: "users",
  },
  {
    path: "/bots",
    icon: "bots",
  },
  {
    path: "/banners",
    icon: "banners",
  },
  {
    path: "/settings",
    icon: "settings",
  },
  {
    path: "/notification-messages",
    icon: "notification-messages",
  },
];

import axios, { AxiosRequestConfig } from "axios";

import Cookie from "./Cookie";

const headers = {
  "Cache-Control": "no-cache, no-store",
};

const authInterceptor = (config: AxiosRequestConfig) => {
  const accessToken = Cookie.getAuthenticationCookie();
  const accessTokenHeader = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};

  return {
    ...config,
    headers: {
      ...config.headers,
      ...accessTokenHeader,
    },
  };
};

const createApiInstance = (configs: AxiosRequestConfig) => {
  const apiInstance = axios.create({
    timeout: 90000,
    headers,
    ...configs,
  });

  apiInstance.interceptors.request.use(authInterceptor);

  return apiInstance;
};

export default createApiInstance;

import { IReduxAction } from "../../../global";
import {
  IListState,
  LIST_LOAD,
  LIST_FULFILLED,
  LIST_CHANGE_PAGE,
  LIST_SEARCH,
  LIST_LOAD_ERROR,
} from "./types";

export const initialState: IListState = {
  loading: false,
  loaded: false,
  list: [],
  controller: "",
  lastLoaded: "",
  page: 1,
  pages: 0,
  search: "",
  filter: {},
  limit: 10,
};

export default function listReducer(
  state = initialState,
  { type, payload }: IReduxAction
): IListState {
  switch (type) {
    case LIST_LOAD:
      return {
        ...state,
        loaded: false,
        loading: true,
        controller: payload?.controller,
        page: payload?.page,
        lastLoaded: payload?.controller,
      };

    case LIST_CHANGE_PAGE:
      return {
        ...state,
        ...payload,
        loading: true,
      };

    case LIST_SEARCH:
      return {
        ...state,
        ...payload,
      };

    case LIST_FULFILLED:
      return {
        ...state,
        loaded: true,
        loading: false,
        ...payload,
      };
    case LIST_LOAD_ERROR:
      return {
        ...state,
        loaded: true,
        loading: false,
        list: [],
      };

    default:
      return state;
  }
}

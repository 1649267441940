import { REHYDRATE } from "redux-persist/es/constants";
import { REDUX_KEY } from "../../../config";
import { IReduxAction } from "../../../global";
import {
  IGlobalState,
  REDIRECT,
  SET_ERROR,
  SET_FIELDS,
  SET_ROUTE,
  TOAST_CLEAR,
  TOAST_SHOW,
} from "./types";

export const initialState: IGlobalState = {
  initialized: process.env.REACT_APP_ENABLE_PERSIST !== "true",
  appearance: "info",
  message: "",
  redirectRoute: "",
  route: "",
  fields: {},
  error: {
    code: 0,
    message: "",
  },
};

export default function globalReducer(
  state = initialState,
  { key, type, payload }: IReduxAction
): IGlobalState {
  switch (type) {
    case TOAST_SHOW:
    case TOAST_CLEAR:
    case REDIRECT:
    case SET_FIELDS:
    case SET_ERROR:
      return {
        ...state,
        ...payload,
      };

    case SET_ROUTE:
      return {
        ...state,
        ...payload,
        error: {
          code: 0,
          message: "",
        },
      };

    case REHYDRATE:
      return key === `${REDUX_KEY}-global`
        ? {
            ...state,
            ...payload,
            initialized: true,
          }
        : state;

    default:
      return {
        ...state,
      };
  }
}

import React, { FC } from "react";
import { useDispatch } from "react-redux";

import Button from "../../components/Button";
import { IList } from "../../state/ducks/list/types";

import "./List.scss";
import { customActions, noDeleteActions, noEditActions, noViewActions } from "../../config";
import { crudDelete } from "../../state/ducks/crud/actions";

interface IActionButtons {
  controller: string;
  row: IList;
  handleClickEdit: Function;
}

const ActionButtons: FC<IActionButtons> = ({ controller, row, handleClickEdit, children }) => {
  const dispatch = useDispatch();

  const hasEditAction = !noEditActions.includes(controller);
  const hasViewAction = !noViewActions.includes(controller);
  const hasDeleteAction = !noDeleteActions.includes(controller);
  const hasCustomAction = customActions[controller] !== undefined;
  const customGridAction = customActions[controller] || [];

  return (
    <div className="wrapButtons">
      {children}
      {(hasViewAction || hasEditAction) && (
      <Button
        key={`action_edit`}
        textClasses="material-icons buttonText"
        onClick={(e: React.MouseEvent<HTMLSpanElement>) => handleClickEdit(row.id.toString(), e)}
        text={hasEditAction ? "edit" : "visibility"}
      />
      )}
      {hasDeleteAction && (
        <Button
          key={`action_delete`}
          classes="delete"
          textClasses="material-icons buttonText"
          onClick={() => {
            if (confirm("Deseja excluir este item?")) {
              dispatch(crudDelete(controller, row.id, false));
            }
          }}
          text="close"
        />
      )}
      {customGridAction.map((customAction, i) => {
        const { icon, condition, action, title, hideList } = customAction;
        const hasCondition = condition !== undefined;
        if (hasCustomAction && (!hasCondition || (condition && condition(row))) && !hideList) {
          return (
            <Button
              key={`action_${icon}_${i}`}
              textClasses="material-icons buttonText"
              onClick={(e) => {
                e.stopPropagation();
                action(dispatch, row, controller);
              }}
              text={icon}
              title={title}
            />
          );
        }
      })}
    </div>
  );
};

export default ActionButtons;

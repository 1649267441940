import { FC } from "react";

import { IList } from "../../state/ducks/list/types";

import "./List.scss";
import { renderTd } from "../../utils/Functions";
import ActionButtons from "./ActionButtons";

interface ITableRow {
  controller: string;
  list: IList[];
  handleClickEdit: Function;
}

const TableRow: FC<ITableRow> = ({ controller, list, handleClickEdit }) => {
  return (
    <>
      {list.map((row: IList, rk: number) => (
        <tr key={`tr_${rk}`}>
          {Object.entries(row).map((value, vk) => renderTd(controller, value, vk))}
          <td className="actions">
            <ActionButtons controller={controller} row={row} handleClickEdit={handleClickEdit} />
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableRow;

import { AxiosRequestConfig } from "axios";

import createApiInstance from "./ApiInstance";
import { API_URL } from "../config";

const apiInstance = createApiInstance({ baseURL: API_URL });

export default class ApiClient {
  static get(endpoint: string, config?: AxiosRequestConfig) {
    return apiInstance.get(endpoint, config);
  }

  static post(endpoint: string, body?: object, config?: AxiosRequestConfig) {
    return apiInstance.post(endpoint, body, {
      ...{ "Content-Type": "application/json; charset=utf-8" },
      ...(config || {}),
    });
  }

  static patch(endpoint: string, body?: object, config?: AxiosRequestConfig) {
    return apiInstance.patch(endpoint, body, {
      ...{ "Content-Type": "application/json; charset=utf-8" },
      ...(config || {}),
    });
  }

  static delete(endpoint: string, config?: AxiosRequestConfig) {
    return apiInstance.delete(endpoint, {
      ...{ "Content-Type": "application/json; charset=utf-8" },
      ...(config || {}),
    });
  }

  static postData(endpoint: string, formData: FormData, config?: AxiosRequestConfig) {
    return apiInstance.post(endpoint, formData, {
      ...{ "Content-Type": "multipart/form-data" },
      ...(config || {}),
    });
  }
}

import { IReduxAction } from "../../../global";
import {
  CRUD_LOAD,
  CRUD_LOAD_FULFILLED,
  CRUD_INSERT,
  CRUD_UPDATE,
  CRUD_DELETE,
  CRUD_SUCCESS,
  CRUD_ERROR,
  CRUD_OPTIONS_LOAD_FULFILLED,
  CRUD_OPTIONS_LOAD,
} from "./types";

export const crudLoad = (controller: string, id: string): IReduxAction => ({
  type: CRUD_LOAD,
  payload: {
    controller,
    id,
  },
});

export const crudLoadFulfilled = (data: any): IReduxAction => ({
  type: CRUD_LOAD_FULFILLED,
  payload: {
    data,
  },
});

export const crudInsert = (controller: string, data: any): IReduxAction => ({
  type: CRUD_INSERT,
  payload: {
    controller,
    data,
  },
});

export const crudUpdate = (controller: string, id: string | number, data: any): IReduxAction => ({
  type: CRUD_UPDATE,
  payload: {
    controller,
    id,
    data,
  },
});

export const crudDelete = (
  controller: string,
  id: string | number,
  doRedirect = true
): IReduxAction => ({
  type: CRUD_DELETE,
  payload: {
    controller,
    id,
    doRedirect,
  },
});

export const crudSuccess = (): IReduxAction => ({
  type: CRUD_SUCCESS,
});

export const crudError = (): IReduxAction => ({
  type: CRUD_ERROR,
});

export const crudOptionsLoad = (controller: string): IReduxAction => ({
  type: CRUD_OPTIONS_LOAD,
  payload: {
    controller
  }
});

export const crudOptionsLoadFulfilled = (data: any): IReduxAction => ({
  type: CRUD_OPTIONS_LOAD_FULFILLED,
  payload: {
    data,
  },
});
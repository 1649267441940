import { IReduxAction } from "../../../global";
import {
  IListLoad,
  LIST_FULFILLED,
  LIST_LOAD,
  LIST_CHANGE_PAGE,
  LIST_SEARCH,
  LIST_LOAD_ERROR,
  IListLoadPayload,
} from "./types";

export const listLoad = ({ controller, page = 1 }: IListLoadPayload): IListLoad => ({
  type: LIST_LOAD,
  payload: {
    controller,
    page,
  },
});

export const listChangePage = (page: number): IReduxAction => ({
  type: LIST_CHANGE_PAGE,
  payload: {
    page,
  },
});

export const listSearch = ({
  search = "",
  filter = {},
  params,
}: {
  search?: string;
  filter?: object;
  params?: object;
}): IReduxAction => ({
  type: LIST_SEARCH,
  payload: {
    search,
    filter,
    params,
  },
});

export const listFulfilled = (list: any[], pages: number, page?: number): IReduxAction => ({
  type: LIST_FULFILLED,
  payload: {
    list,
    pages,
    page,
  },
});

export const listLoadError = (): IReduxAction => ({
  type: LIST_LOAD_ERROR,
});

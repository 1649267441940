import { FC } from "react";
import { useParams } from "react-router-dom";
import { IRoute } from "../../global";
import { useSelector } from "react-redux";
import { IRootState } from "../../state/ducks/types";

import Crud from "../Crud";
import List from "../List";
import Error from "../Error";

export enum IRouteType {
  Crud = "crud",
  List = "List",
}

interface IRouteManager {
  crud?: boolean;
  type?: IRouteType;
}

//@TODO improve parameters...
const RouteManager: FC<IRouteManager> = ({ crud = false, type = IRouteType.Crud }) => {
  const { controller, route }: IRoute = useParams();
  const { error } = useSelector((state: IRootState) => state.global);

  if (error.code !== 0) {
    return <Error code={error.code} message={error.message} />;
  }

  const renderCustom = () => {
    switch (route) {
      default:
        return <Crud />;
    }
  };

  const renderCrud = () => {
    if (!controller || crud) return renderCustom();

    switch (controller) {
      default:
        return type === IRouteType.Crud ? <Crud /> : <List />;
    }
  };

  return <div className="manage full">{renderCrud()}</div>;
};

export default RouteManager;

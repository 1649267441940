import { AppearanceTypes } from "react-toast-notifications";
import { IGenericObject, IReduxAction } from "../../../global";
import {
  TOAST_SHOW,
  TOAST_CLEAR,
  REDIRECT,
  SET_ROUTE,
  GET_FIELDS,
  SET_FIELDS,
  SET_ERROR,
} from "./types";

export const toastShow = (appearance: AppearanceTypes, message?: string): IReduxAction => ({
  type: TOAST_SHOW,
  payload: {
    appearance,
    message,
  },
});

export const toastClear = (): IReduxAction => ({
  type: TOAST_CLEAR,
  payload: {
    appearance: "",
    message: "",
  },
});

export const redirect = (redirectRoute: string = ""): IReduxAction => ({
  type: REDIRECT,
  payload: {
    redirectRoute,
  },
});

export const storeRoute = (route: string = ""): IReduxAction => ({
  type: SET_ROUTE,
  payload: {
    route,
  },
});

export const getFields = (): IReduxAction => ({
  type: GET_FIELDS,
});

export const setFields = (fields: IGenericObject): IReduxAction => ({
  type: SET_FIELDS,
  payload: {
    fields,
  },
});

export const setError = (error: IGenericObject): IReduxAction => ({
  type: SET_ERROR,
  payload: {
    error,
  },
});
